import React from "react";

const JobSupport = ({
  formValues,
  handleChange,
  nextStep,
  prevStep,
}: {
  formValues: any;
  handleChange: any;
  nextStep: () => void;
  prevStep: () => void;
}) => {

  return (
    <div className="p-6 rounded-lg shadow-md bg-white dark:bg-gray-800 space-y-4">
      <h2 className="text-3xl font-semibold mb-6 text-center">Job Support</h2>
      <div className="space-y-4">
        <div className="flex flex-col md:flex-row items-center justify-between">
          <label
            htmlFor="transitionDelivery"
            className="block text-sm font-medium"
          >
            Have you been Transitioned your knowledge & functions?
          </label>
          <input
            id="transitionDelivery"
            type="checkbox"
            checked={formValues.transitionDelivery || false}
            onChange={handleChange("jobSupport", "transitionDelivery")}
            className="ml-2"
          />
        </div>

        <div className="flex flex-col md:flex-row items-center justify-between">
          <label htmlFor="roleStrengths" className="block text-sm font-medium">
            Have you learned the Strengths & Improvements for your role?
          </label>
          <input
            id="roleStrengths"
            type="checkbox"
            checked={formValues.roleStrengths || false}
            onChange={handleChange("jobSupport", "roleStrengths")}
            className="ml-2"
          />
        </div>

        <div className="flex flex-col md:flex-row items-center justify-between">
          <label
            htmlFor="transitionDocument"
            className="block text-sm font-medium"
          >
            Create a Document which outlines all that was taught during
            Transitioning.
          </label>
          <input
            id="transitionDocument"
            type="text"
            value={formValues.transitionDocument || ""}
            onChange={handleChange("jobSupport", "transitionDocument")}
            placeholder="Enter Transition Document link"
            className="ml-2 border border-gray-300 rounded px-2 py-1"
          />
        </div>

        <div className="flex flex-col md:flex-row items-center justify-between">
          <label htmlFor="onboardingLCVP" className="block text-sm font-medium">
            Have you been Onboarded by your LCVP?
          </label>
          <input
            id="onboardingLCVP"
            type="checkbox"
            checked={formValues.onboardingLCVP || false}
            onChange={handleChange("jobSupport", "onboardingLCVP")}
            className="ml-2"
          />
        </div>

        <div className="flex flex-col md:flex-row items-center justify-between">
          <label
            htmlFor="onboardingMembers"
            className="block text-sm font-medium"
          >
            Have you conducted an Onboarding meet with your Members, if any?
          </label>
          <input
            id="onboardingMembers"
            type="checkbox"
            checked={formValues.onboardingMembers || false}
            onChange={handleChange("jobSupport", "onboardingMembers")}
            className="ml-2"
          />
        </div>

        <div className="flex flex-col md:flex-row items-center justify-between">
          <label
            htmlFor="onboardingDocument"
            className="block text-sm font-medium"
          >
            Create a Document which outlines the Key Takeaways of Onboarding.
          </label>
          <input
            id="onboardingDocument"
            type="text"
            value={formValues.onboardingDocument || ""}
            onChange={handleChange("jobSupport", "onboardingDocument")}
            placeholder="Enter Onboarding Document link"
            className="ml-2 border border-gray-300 rounded px-2 py-1"
          />
        </div>

        <div className="flex flex-col md:flex-row items-center justify-between">
          <label htmlFor="toolsLCVP" className="block text-sm font-medium">
            Have you received Tools & Documents for your role by your LCVP?
          </label>
          <input
            id="toolsLCVP"
            type="checkbox"
            checked={formValues.toolsLCVP || false}
            onChange={handleChange("jobSupport", "toolsLCVP")}
            className="ml-2"
          />
        </div>

        <div className="flex flex-col md:flex-row items-center justify-between">
          <label htmlFor="toolsTLD" className="block text-sm font-medium">
            Have you received Tools & Documents for your role by your Preceding
            TLD?
          </label>
          <input
            id="toolsTLD"
            type="checkbox"
            checked={formValues.toolsTLD || false}
            onChange={handleChange("jobSupport", "toolsTLD")}
            className="ml-2"
          />
        </div>

        <div className="flex flex-col md:flex-row items-center justify-between">
          <label htmlFor="downscaleTools" className="block text-sm font-medium">
            Have you downscaled said Tools & Documents with your Members, if
            any?
          </label>
          <input
            id="downscaleTools"
            type="checkbox"
            checked={formValues.downscaleTools || false}
            onChange={handleChange("jobSupport", "downscaleTools")}
            className="ml-2"
          />
        </div>

        <div className="flex flex-col md:flex-row items-center justify-between">
          <label htmlFor="toolsDocument" className="block text-sm font-medium">
            Document the supporting Tools & Documents in a single folder.
          </label>
          <input
            id="toolsDocument"
            type="text"
            value={formValues.toolsDocument || ""}
            onChange={handleChange("jobSupport", "toolsDocument")}
            placeholder="Enter Tools Document link"
            className="ml-2 border border-gray-300 rounded px-2 py-1"
          />
        </div>
      </div>
      <div className="flex justify-between space-x-4 mt-6">
        <button
          onClick={prevStep}
          className="px-6 py-2 bg-gray-500 text-white rounded hover:bg-gray-600"
        >
          Back
        </button>
        <button
          onClick={nextStep}
          className="px-6 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default JobSupport;
