import React, { useState, useEffect, FormEvent } from "react";
import axios from "axios";

const TeamPractice = ({
  formValues,
  handleChange,
  nextStep,
  prevStep,
  userId,
}: {
  formValues: any;
  handleChange: any;
  nextStep: () => void;
  prevStep: () => void;
  userId: string;
}) => {
  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    try {
      await axios.post("https://api.nexusmxp.com/api/checklist/submit", {
        userId,
        formData: formValues,
      });
      alert("Form submitted successfully");
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };
    
         return (
          <div className="p-6 rounded-lg shadow-md bg-white dark:bg-gray-800 space-y-4">
            <h2 className="text-3xl font-semibold mb-6 text-center">
              Team Practice
            </h2>
            <div className="space-y-4">
              <div className="flex flex-col md:flex-row items-center justify-between">
                <label htmlFor="teamVision" className="block text-sm font-medium">
                  Have you created a Team Vision?
                </label>
                <input
                  id="teamVision"
                  type="checkbox"
                  checked={formValues.teamVision}
                  onChange={handleChange("teamPractice", "teamVision")}
                  className="ml-2"
                />
              </div>

              <div className="flex flex-col md:flex-row items-center justify-between">
                <label htmlFor="teamRules" className="block text-sm font-medium">
                  Have you established Team Rules & Meeting Routines?
                </label>
                <input
                  id="teamRules"
                  type="checkbox"
                  checked={formValues.teamRules}
                  onChange={handleChange("teamPractice", "teamRules")}
                  className="ml-2"
                />
              </div>

              <div className="flex flex-col md:flex-row items-center justify-between">
                <label htmlFor="teamGoals" className="block text-sm font-medium">
                  Have you created Team Goals & Strategies?
                </label>
                <input
                  id="teamGoals"
                  type="checkbox"
                  checked={formValues.teamGoals}
                  onChange={handleChange("teamPractice", "teamGoals")}
                  className="ml-2"
                />
              </div>

              <div className="flex flex-col md:flex-row items-center justify-between">
                <label htmlFor="teamKPIs" className="block text-sm font-medium">
                  Have you decided upon the KPIs & MoS?
                </label>
                <input
                  id="teamKPIs"
                  type="checkbox"
                  checked={formValues.teamKPIs}
                  onChange={handleChange("teamPractice", "teamKPIs")}
                  className="ml-2"
                />
              </div>

              <div className="flex flex-col md:flex-row items-center justify-between">
                <label htmlFor="gtkeoMeet" className="block text-sm font-medium">
                  Have you conducted a GTKEO Meet?
                </label>
                <input
                  id="gtkeoMeet"
                  type="checkbox"
                  checked={formValues.gtkeoMeet}
                  onChange={handleChange("teamPractice", "gtkeoMeet")}
                  className="ml-2"
                />
              </div>

              <div className="flex flex-col md:flex-row items-center justify-between">
                <label htmlFor="teamDays" className="block text-sm font-medium">
                  Have you established Team Days?
                </label>
                <input
                  id="teamDays"
                  type="checkbox"
                  checked={formValues.teamDays}
                  onChange={handleChange("teamPractice", "teamDays")}
                  className="ml-2"
                />
              </div>

              <div className="flex flex-col md:flex-row items-center justify-between">
                <label htmlFor="engagementRoutines" className="block text-sm font-medium">
                  Have you decided on Engagement Routines?
                </label>
                <input
                  id="engagementRoutines"
                  type="checkbox"
                  checked={formValues.engagementRoutines}
                  onChange={handleChange("teamPractice", "engagementRoutines")}
                  className="ml-2"
                />
              </div>

              <div className="flex flex-col md:flex-row items-center justify-between">
                <label htmlFor="engagementRoutinesShare" className="block text-sm font-medium">
                  Share the Engagement Routines
                </label>
                <input
                  id="engagementRoutinesShare"
                  type="text"
                  value={formValues.engagementRoutinesShare}
                  onChange={handleChange("teamPractice", "engagementRoutinesShare")}
                  placeholder="Enter Engagement Routines Share link"
                  className="ml-2 border border-gray-300 rounded px-2 py-1"
                />
              </div>

              <div className="flex flex-col md:flex-row items-center justify-between">
                <label htmlFor="teamPerformanceEvaluation" className="block text-sm font-medium">
                  Are you evaluating Team Performance?
                </label>
                <input
                  id="teamPerformanceEvaluation"
                  type="checkbox"
                  checked={formValues.teamPerformanceEvaluation}
                  onChange={handleChange("teamPractice", "teamPerformanceEvaluation")}
                  className="ml-2"
                />
              </div>

              <div className="flex flex-col md:flex-row items-center justify-between">
                <label htmlFor="performanceEvaluationSystem" className="block text-sm font-medium">
                  Share your Performance Evaluation system
                </label>
                <input
                  id="performanceEvaluationSystem"
                  type="text"
                  value={formValues.performanceEvaluationSystem}
                  onChange={handleChange("teamPractice", "performanceEvaluationSystem")}
                  placeholder="Enter Performance Evaluation System link"
                  className="ml-2 border border-gray-300 rounded px-2 py-1"
                />
              </div>

              <div className="flex flex-col md:flex-row items-center justify-between">
                <label htmlFor="quarterlyImpactReports" className="block text-sm font-medium">
                  Share your Quarterly Impact Reports
                </label>
                <input
                  id="quarterlyImpactReports"
                  type="text"
                  value={formValues.quarterlyImpactReports}
                  onChange={handleChange("teamPractice", "quarterlyImpactReports")}
                  placeholder="Enter Quarterly Impact Reports link"
                  className="ml-2 border border-gray-300 rounded px-2 py-1"
                />
              </div>

              <div className="flex flex-col md:flex-row items-center justify-between">
                <label htmlFor="leadSpaces" className="block text-sm font-medium">
                  Are you providing/engaging in Lead Spaces?
                </label>
                <input
                  id="leadSpaces"
                  type="checkbox"
                  checked={formValues.leadSpaces}
                  onChange={handleChange("teamPractice", "leadSpaces")}
                  className="ml-2"
                />
              </div>

              <div className="flex flex-col md:flex-row items-center justify-between">
                <label htmlFor="personalFunctionalDevelopment" className="block text-sm font-medium">
                  Are you providing/engaging in Personal & Functional Development Spaces?
                </label>
                <input
                  id="personalFunctionalDevelopment"
                  type="checkbox"
                  checked={formValues.personalFunctionalDevelopment}
                  onChange={handleChange("teamPractice", "personalFunctionalDevelopment")}
                  className="ml-2"
                />
              </div>

              <div className="flex flex-col md:flex-row items-center justify-between">
                <label htmlFor="functionalSpaces" className="block text-sm font-medium">
                  Has your LCVP provided Functional Spaces?
                </label>
                <input
                  id="functionalSpaces"
                  type="checkbox"
                  checked={formValues.functionalSpaces}
                  onChange={handleChange("teamPractice", "functionalSpaces")}
                  className="ml-2"
                />
              </div>

              <div className="flex flex-col md:flex-row items-center justify-between">
                <label htmlFor="gbDevelopmentSpaces" className="block text-sm font-medium">
                  Have any GB Development spaces been conducted?
                </label>
                <input
                  id="gbDevelopmentSpaces"
                  type="checkbox"
                  checked={formValues.gbDevelopmentSpaces}
                  onChange={handleChange("teamPractice", "gbDevelopmentSpaces")}
                  className="ml-2"
                />
              </div>

              <div className="flex flex-col md:flex-row items-center justify-between">
                <label htmlFor="teamClosingMeet" className="block text-sm font-medium">
                  Have you conducted a Team Closing meet?
                </label>
                <input
                  id="teamClosingMeet"
                  type="checkbox"
                  checked={formValues.teamClosingMeet}
                  onChange={handleChange("teamPractice", "teamClosingMeet")}
                  className="ml-2"
                />
              </div>

              <div className="flex flex-col md:flex-row items-center justify-between">
                <label htmlFor="portfolioClosingMeet" className="block text-sm font-medium">
                  Has your LCVP conducted a Portfolio Closing Meet?
                </label>
                <input
                  id="portfolioClosingMeet"
                  type="checkbox"
                  checked={formValues.portfolioClosingMeet}
                  onChange={handleChange("teamPractice", "portfolioClosingMeet")}
                  className="ml-2"
                />
              </div>

              <div className="flex flex-col md:flex-row items-center justify-between">
                <label htmlFor="semesterImpactReport" className="block text-sm font-medium">
                  Share a Semester Impact Report
                </label>
                <input
                  id="semesterImpactReport"
                  type="text"
                  value={formValues.semesterImpactReport}
                  onChange={handleChange("teamPractice", "semesterImpactReport")}
                  placeholder="Enter Semester Impact Report link"
                  className="ml-2 border border-gray-300 rounded px-2 py-1"
                />
              </div>
            </div>
            <div className="flex justify-between space-x-4 mt-6">
              <button
                onClick={prevStep}
                className="px-6 py-2 bg-gray-500 text-white rounded hover:bg-gray-600"
              >
                Back
              </button>
              {/* <button
                onClick={handleSubmit}
                className="px-6 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
              >
                Submit
              </button> */}
            </div>
          </div>
        );
};

export default TeamPractice;
