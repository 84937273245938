import React from "react";

const JobDesign = ({
  formValues,
  handleChange,
  nextStep,
  prevStep,
}: {
  formValues: any;
  handleChange: any;
  nextStep: () => void;
  prevStep: () => void;
}) => {
  return (
    <div className="p-6 rounded-lg shadow-md bg-white dark:bg-gray-800 space-y-4">
      <h2 className="text-3xl font-semibold mb-6 text-center">Job Design</h2>
      <div className="space-y-4">
        <div className="flex flex-col md:flex-row items-center justify-between">
          <label htmlFor="jobDescription" className="block text-sm font-medium">
            Have you thoroughly been explained what your Job is about?
          </label>
          <input
            id="jobDescription"
            type="checkbox"
            checked={formValues.jobDescription}
            onChange={handleChange("jobDesign", "jobDescription")}
            className="ml-2"
          />
        </div>

        <div className="flex flex-col md:flex-row items-center justify-between">
          <label htmlFor="jobKpis" className="block text-sm font-medium">
            Have you been thoroughly explained what your KPIs & MoS are?
          </label>
          <input
            id="jobKpis"
            type="checkbox"
            checked={formValues.jobKpis}
            onChange={handleChange("jobDesign", "jobKpis")}
            className="ml-2"
          />
        </div>

        <div className="flex flex-col md:flex-row items-center justify-between">
          <label
            htmlFor="personalDevelopment"
            className="block text-sm font-medium"
          >
            Have you filled your PDP?
          </label>
          <input
            id="personalDevelopment"
            type="text"
            value={formValues.personalDevelopment}
            onChange={handleChange("jobDesign", "personalDevelopment")}
            placeholder="Enter Personal Development link"
            className="ml-2 border border-gray-300 rounded px-2 py-1"
          />
        </div>

        <div className="flex flex-col md:flex-row items-center justify-between">
          <label htmlFor="pdpSeptember" className="block text-sm font-medium">
            Have you revisited your PDP with your LCVP during September?
          </label>
          <input
            id="pdpSeptember"
            type="checkbox"
            checked={formValues.pdpSeptember}
            onChange={handleChange("jobDesign", "pdpSeptember")}
            className="ml-2"
          />
        </div>

        <div className="flex flex-col md:flex-row items-center justify-between">
          <label htmlFor="pdpDecember" className="block text-sm font-medium">
            Have you revisited your PDP with your LCVP during December?
          </label>
          <input
            id="pdpDecember"
            type="checkbox"
            checked={formValues.pdpDecember}
            onChange={handleChange("jobDesign", "pdpDecember")}
            className="ml-2"
          />
        </div>
      </div>
      <div className="flex justify-between space-x-4 mt-6">
        <button
          onClick={prevStep}
          className="px-6 py-2 bg-gray-500 text-white rounded hover:bg-gray-600"
        >
          Back
        </button>
        <button
          onClick={nextStep}
          className="px-6 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default JobDesign;
