import React from "react";

const CommunityEngagement = ({
  formValues,
  handleChange,
  nextStep,
  prevStep,
}: {
  formValues: any;
  handleChange: any;
  nextStep: () => void;
  prevStep: () => void;
}) => {
  return (
    <div className="p-6 rounded-lg shadow-md bg-white dark:bg-gray-800 space-y-4">
      <h2 className="text-3xl font-semibold mb-6 text-center">
        Community Engagement
      </h2>
      <div className="space-y-4">
        <div className="flex flex-col md:flex-row items-center justify-between">
          <label
            htmlFor="functionalEmail"
            className="block text-sm font-medium"
          >
            Do you have a functional AIESEC Email?
          </label>
          <input
            id="functionalEmail"
            type="checkbox"
            checked={formValues.functionalEmail}
            onChange={handleChange("communityEngagement", "functionalEmail")}
            className="ml-2"
          />
        </div>

        <div className="flex flex-col md:flex-row items-center justify-between">
          <label htmlFor="emailSignature" className="block text-sm font-medium">
            Have you added your External Email Signature?
          </label>
          <input
            id="emailSignature"
            type="checkbox"
            checked={formValues.emailSignature}
            onChange={handleChange("communityEngagement", "emailSignature")}
            className="ml-2"
          />
        </div>

        <div className="flex flex-col md:flex-row items-center justify-between">
          <label htmlFor="functionalEXPA" className="block text-sm font-medium">
            Do you have a functional EXPA which you know how to use?
          </label>
          <input
            id="functionalEXPA"
            type="checkbox"
            checked={formValues.functionalEXPA}
            onChange={handleChange("communityEngagement", "functionalEXPA")}
            className="ml-2"
          />
        </div>

        <div className="flex flex-col md:flex-row items-center justify-between">
          <label
            htmlFor="accountabilityRoutines"
            className="block text-sm font-medium"
          >
            Are Accountability Routines (Eg: Townhalls) taking place in GBMs &
            Portfolio meets?
          </label>
          <input
            id="accountabilityRoutines"
            type="checkbox"
            checked={formValues.accountabilityRoutines}
            onChange={handleChange(
              "communityEngagement",
              "accountabilityRoutines"
            )}
            className="ml-2"
          />
        </div>

        <div className="flex flex-col md:flex-row items-center justify-between">
          <label
            htmlFor="gbEngagementEvents"
            className="block text-sm font-medium"
          >
            Are GB Engagement Events taking place?
          </label>
          <input
            id="gbEngagementEvents"
            type="checkbox"
            checked={formValues.gbEngagementEvents}
            onChange={handleChange("communityEngagement", "gbEngagementEvents")}
            className="ml-2"
          />
        </div>

        <div className="flex flex-col md:flex-row items-center justify-between">
          <label
            htmlFor="portfolioEngagementEvents"
            className="block text-sm font-medium"
          >
            Are Portfolio Engagement Events taking place?
          </label>
          <input
            id="portfolioEngagementEvents"
            type="checkbox"
            checked={formValues.portfolioEngagementEvents}
            onChange={handleChange(
              "communityEngagement",
              "portfolioEngagementEvents"
            )}
            className="ml-2"
          />
        </div>

        <div className="flex flex-col md:flex-row items-center justify-between">
          <label
            htmlFor="quarterlyAppraisals"
            className="block text-sm font-medium"
          >
            Are Quarterly Appraisals at least taking place?
          </label>
          <input
            id="quarterlyAppraisals"
            type="checkbox"
            checked={formValues.quarterlyAppraisals}
            onChange={handleChange(
              "communityEngagement",
              "quarterlyAppraisals"
            )}
            className="ml-2"
          />
        </div>

        <div className="flex flex-col md:flex-row items-center justify-between">
          <label htmlFor="quarterlyRnRs" className="block text-sm font-medium">
            Are Quarterly RnRs at least taking place?
          </label>
          <input
            id="quarterlyRnRs"
            type="checkbox"
            checked={formValues.quarterlyRnRs}
            onChange={handleChange("communityEngagement", "quarterlyRnRs")}
            className="ml-2"
          />
        </div>
      </div>
      <div className="flex justify-between space-x-4 mt-6">
        <button
          onClick={prevStep}
          className="px-6 py-2 bg-gray-500 text-white rounded hover:bg-gray-600"
        >
          Back
        </button>
        <button
          onClick={nextStep}
          className="px-6 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default CommunityEngagement;
