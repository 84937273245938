"use client";
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { auth, googleProvider, db } from '../firebase/firebase';
import { signInWithPopup, signInWithEmailAndPassword } from 'firebase/auth';
import { getDoc, doc } from 'firebase/firestore';
import { useUser } from '../contexts/UserContext';
import { Label } from "./ui/label";
import { Input } from "./ui/input";
import { cn } from "../lib/utils";
import { IconBrandGoogle } from "@tabler/icons-react";

const Signin: React.FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');
  const navigate = useNavigate();
  const { setRole } = useUser();
  const [showVerificationMessage, setShowVerificationMessage] = useState(false);
  const location = useLocation();


  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    if (queryParams.get('verification') === 'success') {
      setShowVerificationMessage(true);
    }
  }, [location]);



  const handleEmailSignin = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!email.endsWith('@aiesec.net')) {
      setMessage('Only AIESEC emails are allowed');
      return;
    }

    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      if (!user.emailVerified) {
        setMessage('Please verify your email before signing in.');
        return;
      }

      const userDoc = await getDoc(doc(db, 'users', user.uid));
      if (userDoc.exists()) {
        const userRole = userDoc.data().role;
        setRole(userRole);
      }

      navigate('/dashboard');
    } catch (error: any) {
      console.error(error);
      setMessage('Signin failed: ' + error.message);
    }
  };

  const signInWithGoogle = async () => {
    try {
      const result = await signInWithPopup(auth, googleProvider);
      const email = result.user?.email;
      if (email && email.endsWith('@aiesec.net')) {
        console.log(true);
        const user = result.user;
        if (!user.emailVerified) {
          setMessage('Please verify your email before signing in.');
          auth.signOut();
          return;
        }

        const userDoc = await getDoc(doc(db, 'users', user.uid));
        if (userDoc.exists()) {
          const userRole = userDoc.data().role;
          setRole(userRole);
        }

        navigate('/dashboard');
      } else {
        alert('You must sign in with an @aiesec.net email address');
        auth.signOut();
      }
    } catch (error) {
      console.error('Error signing in with Google:', error);
      alert('Error signing in. Please try again.');
    }
  };

  return (
    <div className="max-w-md w-full mx-auto rounded-none md:rounded-2xl md:p-8 p-4 shadow-input bg-white dark:bg-black pt-20">
      <h2 className="font-bold text-xl text-neutral-800 dark:text-neutral-200">
        Welcome to Nexus
      </h2>
  

      <form className="my-8" onSubmit={handleEmailSignin}>
      {showVerificationMessage && (
        <div className="bg-green-100 text-green-800 p-4 rounded-md mb-4">
          A verification email has been sent. Please check your inbox and verify your account before signing in.
        </div>
      )}
        <div className="space-y-2 mb-4">
          <LabelInputContainer>
            <Label htmlFor="email">Email Address</Label>
            <Input id="email" placeholder="user@aiesec.net" type="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
          </LabelInputContainer>
        </div>
        <LabelInputContainer className="mb-4">
          <Label htmlFor="password">Password</Label>
          <Input id="password" placeholder="••••••••" type="password" value={password} onChange={(e) => setPassword(e.target.value)} required />
        </LabelInputContainer>

        <button className="bg-gradient-to-br relative group/btn from-black dark:from-zinc-900 dark:to-zinc-900 to-neutral-600 block dark:bg-zinc-800 w-full text-white rounded-md h-10 font-medium shadow-[0px_1px_0px_0px_#ffffff40_inset,0px_-1px_0px_0px_#ffffff40_inset] dark:shadow-[0px_1px_0px_0px_var(--zinc-800)_inset,0px_-1px_0px_0px_var(--zinc-800)_inset]" type="submit">
          Sign in &rarr;
          <BottomGradient />
        </button>

        <div className="bg-gradient-to-r from-transparent via-neutral-300 dark:via-neutral-700 to-transparent my-8 h-[1px] w-full" />

        <div className="flex flex-col space-y-4">
          <button onClick={signInWithGoogle} className="relative group/btn flex space-x-2 items-center justify-start px-4 w-full text-black rounded-md h-10 font-medium shadow-input bg-gray-50 dark:bg-zinc-900 dark:shadow-[0px_0px_1px_1px_var(--neutral-800)]" type="button">
            <IconBrandGoogle className="h-4 w-4 text-neutral-800 dark:text-neutral-300" />
            <span className="text-neutral-700 dark:text-neutral-300 text-sm">Google</span>
            <BottomGradient />
          </button>
          <button onClick={() => { navigate('/signup') }} className="relative group/btn flex space-x-2 items-center justify-start px-4 w-full text-black rounded-md h-10 font-medium shadow-input bg-gray-50 dark:bg-zinc-900 dark:shadow-[0px_0px_1px_1px_var(--neutral-800)]" type="button">
            <span className="text-neutral-700 dark:text-neutral-300 text-sm">Sign Up</span>
            <BottomGradient />
          </button>
        </div>

        <div className="mt-4 text-center">
          <button
            onClick={() => navigate('/reset-password')}
            className="text-sm text-blue-600 hover:underline"
          >
            Forgot Password?
          </button>
        </div>

        {message && <p className="mt-5 font-semibold text-center text-red-500">{message}</p>}
      </form>
    </div>
  );
};

const BottomGradient = () => {
  return (
    <>
      <span className="group-hover/btn:opacity-100 block transition duration-500 opacity-0 absolute h-px w-full -bottom-px inset-x-0 bg-gradient-to-r from-transparent via-cyan-500 to-transparent" />
      <span className="group-hover/btn:opacity-100 blur-sm block transition duration-500 opacity-0 absolute h-px w-1/2 mx-auto -bottom-px inset-x-10 bg-gradient-to-r from-transparent via-indigo-500 to-transparent" />
    </>
  );
};

const LabelInputContainer = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => {
  return (
    <div className={cn("flex flex-col space-y-2 w-full", className)}>
      {children}
    </div>
  );
};

export default Signin;
