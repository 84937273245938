import React, { useState, useEffect, FormEvent } from "react";
import axios from "axios";
import { Button } from "./moving-border"; // Assume this is a custom button component
import GeneralDetails from "./Checklist/GeneralDetails";
import JobDesign from "./Checklist/JobDesign";
import JobSupport from "./Checklist/JobSupport";
import Community from "./Checklist/Community";
import CommunityEngagement from "./Checklist/CommunityEngagement";
import Environment from "./Checklist/Environment";
import TeamPractice from "./Checklist/TeamPractice";

const MultiStepChecklistForm = ({ userId }: { userId: string }) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [formValues, setFormValues] = useState({
    generalDetail: {
      firstName: "",
      lastName: "",
      portfolio: "",
      lcName: "",
    },
    jobDesign: {
      jobDescription: false,
      jobKpis: false,
      personalDevelopment: "",
      pdpSeptember: false,
      pdpDecember: false,
    },
    jobSupport: {
      transitionDelivery: false,
      roleStrengths: false,
      transitionDocument: "",
      onboardingLCVP: false,
      onboardingMembers: false,
      onboardingDocument: "",
      toolsLCVP: false,
      toolsTLD: false,
      downscaleTools: false,
      toolsDocument: "",
    },
    community: {
      internalComms: false,
      portfolioComms: false,
      nationalUpdates: false,
      facebookGroup: false,
      hrPolicy: false,
      codeOfEthics: false,
      hrEthicsProof: "",
    },
    communityEngagement: {
      functionalEmail: false,
      emailSignature: false,
      functionalEXPA: false,
      accountabilityRoutines: false,
      gbEngagementEvents: false,
      portfolioEngagementEvents: false,
      quarterlyAppraisals: false,
      quarterlyRnRs: false,
    },
    environmentWellbeing: {
      physicalWorkspace: false,
      virtualWorkspace: false,
      workspacePlans: "",
      o2oRoutine: false,
      o2oMembers: false,
      tlCoaching: false,
      mentalWellbeing: false,
    },
    teamPractice: {
      teamVision: false,
      teamRules: false,
      teamGoals: false,
      teamKPIs: false,
      gtkeoMeet: false,
      teamDays: false,
      engagementRoutines: false,
      engagementRoutinesShare: "",
      teamPerformanceEvaluation: false,
      performanceEvaluationSystem: "",
      quarterlyImpactReports: "",
      leadSpaces: false,
      personalFunctionalDevelopment: false,
      functionalSpaces: false,
      gbDevelopmentSpaces: false,
      teamClosingMeet: false,
      portfolioClosingMeet: false,
      semesterImpactReport: "",
    },
  });

  // Fetch saved data on load
  useEffect(() => {
    const fetchFormData = async () => {
      try {
        const response = await axios.get(
          `https://api.nexusmxp.com/api/checklist/${userId}`
        );
        if (response.data) {
          setFormValues(response.data);
        }
      } catch (error) {
        console.error("Error fetching saved form data:", error);
      }
    };

    fetchFormData();
  }, [userId]);

  const nextStep = () => {
    setCurrentStep((prevStep) => prevStep + 1);
  };

  const prevStep = () => {
    setCurrentStep((prevStep) => prevStep - 1);
  };

  const handleChange =
    (section: keyof typeof formValues, item: string) => (event: any) => {
      const value =
        event.target.type === "checkbox"
          ? event.target.checked
          : event.target.value;

      setFormValues((prevFormValues) => ({
        ...prevFormValues,
        [section]: {
          ...prevFormValues[section],
          [item]: value,
        },
      }));
    };

  const handleSaveProgress = async () => {
    try {
      await axios.post("https://api.nexusmxp.com/api/checklist/submit", {
        userId,
        formData: formValues,
      });
      alert("Progress saved successfully");
    } catch (error) {
      console.error("Error saving progress:", error);
    }
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    try {
      await axios.post("https://api.nexusmxp.com/api/checklist/submit", {
        userId,
        formData: formValues,
      });
      alert("Form submitted successfully");
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const renderStep = () => {
    switch (currentStep) {
      case 1:
        return (
          <GeneralDetails
            formValues={formValues.generalDetail}
            handleChange={handleChange}
            nextStep={nextStep}
          />
        );
      case 2:
        return (
          <JobDesign
            formValues={formValues.jobDesign}
            handleChange={handleChange}
            nextStep={nextStep}
            prevStep={prevStep}
          />
        );
      case 3:
        return (
          <JobSupport
            formValues={formValues.jobSupport}
            handleChange={handleChange}
            nextStep={nextStep}
            prevStep={prevStep}
          />
        );
      case 4:
        return (
          <Community
            formValues={formValues.community}
            handleChange={handleChange}
            nextStep={nextStep}
            prevStep={prevStep}
          />
        );
      case 5:
        return (
          <CommunityEngagement
            formValues={formValues.communityEngagement}
            handleChange={handleChange}
            nextStep={nextStep}
            prevStep={prevStep}
          />
        );
      case 6:
        return (
          <Environment
            formValues={formValues.environmentWellbeing}
            handleChange={handleChange}
            nextStep={nextStep}
            prevStep={prevStep}
          />
        );
      case 7:
        return (
          <TeamPractice
            formValues={formValues.teamPractice}
            handleChange={handleChange}
            nextStep={nextStep}
            prevStep={prevStep}
            userId={userId}
          />
        );
      default:
        return (
          <div className="text-center text-2xl">
            <p className="pb-10">Form Completed</p>
            <Button
              onClick={handleSubmit}
              borderRadius="1.75rem"
              className="bg-white dark:bg-slate-900 text-black dark:text-white border-neutral-200 dark:border-slate-800 border-black"
            >
              Submit
            </Button>
          </div>
        );
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100 px-2 sm:px-4">
      <div className="w-full max-w-4xl p-2 sm:p-6">
        <h1 className="text-2xl sm:text-3xl font-bold pb-6 sm:pb-10 text-center">
          MX Standards Form
        </h1>
        <div className="bg-white p-4 sm:p-8 rounded-lg shadow-md dark:bg-gray-800 mx-auto w-full">
          {renderStep()}
          <div className="flex justify-between mt-4">
            <Button
              onClick={handleSaveProgress}
              borderRadius="1.75rem"
              className="bg-white dark:bg-slate-900 text-black dark:text-white border-neutral-200 dark:border-slate-800 border-black"
            >
              Save Progress / Submit
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MultiStepChecklistForm;
