"use client";
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth, db, googleProvider } from '../firebase/firebase';
import { createUserWithEmailAndPassword, signInWithPopup, sendEmailVerification, fetchSignInMethodsForEmail } from 'firebase/auth';
import { setDoc, doc } from 'firebase/firestore';
import { useUser } from '../contexts/UserContext';
import { Label } from "./ui/label";
import { Input } from "./ui/input";
import { cn } from "../lib/utils";
import { IconBrandGoogle } from "@tabler/icons-react";

const Signup: React.FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [role, setRole] = useState<'user' | 'tm' | 'tl' | 'lcvp' | 'mcvp' | 'admin'>('user');
  const [message, setMessage] = useState('');
  const navigate = useNavigate();
  const { setRole: setUserRole } = useUser();

  // const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
  //   e.preventDefault();

  //   if (password !== confirmPassword) {
  //     setMessage('Passwords do not match');
  //     return;
  //   }

  //   if (!email.endsWith('@aiesec.net')) {
  //     setMessage('Only AIESEC emails are allowed');
  //     return;
  //   }

  //   if (!role) {
  //     setMessage('Please select a role');
  //     return;
  //   }

  //   try {
  //     const userCredential = await createUserWithEmailAndPassword(auth, email, password);
  //     const user = userCredential.user;

  //     await setDoc(doc(db, 'users', user.uid), {
  //       email: user.email,
  //       role: role
  //     });

  //     await sendEmailVerification(user);
  //     setMessage('Signup successful! Please check your email to verify your account.');

  //     setUserRole(role);
  //     setEmail('');
  //     setPassword('');
  //     setConfirmPassword('');

  //     // Redirect to sign-in page
  //     navigate('/signin?verification=success');
  //   } catch (error: any) {
  //     console.error(error);
  //     setMessage('Signup failed: ' + error.message);
  //   }
  // };
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
  
    if (password !== confirmPassword) {
      setMessage('Passwords do not match');
      return;
    }
  
    if (!email.endsWith('@aiesec.net')) {
      setMessage('Only AIESEC emails are allowed');
      return;
    }
  
    if (!role) {
      setMessage('Please select a role');
      return;
    }
  
    try {
      // Check if the email is already registered
      const signInMethods = await fetchSignInMethodsForEmail(auth, email);
      if (signInMethods.length > 0) {
        setMessage('This email is already registered. Please sign in or use a different email.');
        return;
      }
  
      // Proceed with account creation if no methods are associated with the email
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
  
      await setDoc(doc(db, 'users', user.uid), {
        email: user.email,
        role: role
      });
  
      await sendEmailVerification(user);
      setMessage('Signup successful! Please check your email to verify your account.');
  
      setUserRole(role);
      setEmail('');
      setPassword('');
      setConfirmPassword('');
  
      // Redirect to sign-in page
      navigate('/signin?verification=success');
    } catch (error: any) {
      console.error(error);
      setMessage('Signup failed: ' + error.message);
    }
  };
  
  // const signUpWithGoogle = async () => {
  //   try {
  //     const result = await signInWithPopup(auth, googleProvider);
  //     const email = result.user?.email;
  //     if (email && email.endsWith('@aiesec.net')) {
  //       if (!role) {
  //         setMessage('Please select a role');
  //         return;
  //       }

  //       await setDoc(doc(db, 'users', result.user.uid), {
  //         email: result.user.email,
  //         role: role
  //       });

  //       await sendEmailVerification(result.user);
  //       setMessage('Signup successful! Please check your email to verify your account.');

  //       setUserRole(role);
  //       setEmail('');
  //       setPassword('');
  //       setConfirmPassword('');

  //       // Redirect to sign-in page
  //       navigate('/signin?verification=success');
  //     } else {
  //       alert('You must sign up with an @aiesec.net email address');
  //       auth.signOut();
  //     }
  //   } catch (error) {
  //     console.error('Error signing up with Google:', error);
  //     alert('Error signing up. Please try again.');
  //   }
  // };

  const signUpWithGoogle = async () => {
    try {
      // Sign in with Google
      const result = await signInWithPopup(auth, googleProvider);
      const email = result.user?.email;
  
      // Ensure the email is from AIESEC domain
      if (email && email.endsWith('@aiesec.net')) {
        
        // Check if the email is already registered with another provider
        const signInMethods = await fetchSignInMethodsForEmail(auth, email);
  
        // If the email is associated with another provider and it's not Google, prevent signup
        if (signInMethods.length > 0 && !signInMethods.includes('google.com')) {
          setMessage('This email is already registered with another provider. Please sign in.');
          await auth.signOut();  // Log the user out
          return;
        }
  
        if (!role) {
          setMessage('Please select a role');
          return;
        }
  
        // If the email is valid and not used with another provider, proceed with Google signup
        await setDoc(doc(db, 'users', result.user.uid), {
          email: result.user.email,
          role: role
        });
  
        // Send email verification
        await sendEmailVerification(result.user);
        setMessage('Signup successful! Please check your email to verify your account.');
  
        setUserRole(role);
        setEmail('');
        setPassword('');
        setConfirmPassword('');
  
        // Redirect to sign-in page
        navigate('/signin?verification=success');
      } else {
        // If email is not an AIESEC email, log the user out and show an error message
        await auth.signOut();  // Sign out immediately
        alert('You must sign up with an @aiesec.net email address.');
      }
    } catch (error: any) {
      if (error.code === 'auth/account-exists-with-different-credential') {
        setMessage('This email is already registered with another provider. Please sign in.');
      } else {
        setMessage('Error signing up with Google:'+ error.message);
      }
    }
  };
  
  
  
  
  
  return (
    <div className="max-w-md w-full mx-auto rounded-none md:rounded-2xl md:p-8 p-4 shadow-input bg-white dark:bg-black pt-20 sm:px-4">
      <h2 className="font-bold text-xl text-neutral-800 dark:text-neutral-200">
        Welcome to Nexus
      </h2>
      <p className="text-neutral-600 text-sm max-w-sm mt-2 dark:text-neutral-300">
        Sign in with AIESEC Email
      </p>

      <form className="my-8" onSubmit={handleSubmit}>
        <div className="space-y-2 mb-4">
          <LabelInputContainer>
            <Label htmlFor="email">Email Address</Label>
            <Input id="email" placeholder="user@aiesec.net" type="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
          </LabelInputContainer>
        </div>
        <LabelInputContainer className="mb-4">
          <Label htmlFor="password">Password</Label>
          <Input id="password" placeholder="••••••••" type="password" value={password} onChange={(e) => setPassword(e.target.value)} required />
        </LabelInputContainer>
        <LabelInputContainer className="mb-4">
          <Label htmlFor="confirmPassword">Confirm Password</Label>
          <Input id="confirmPassword" placeholder="••••••••" type="password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} required />
        </LabelInputContainer>
        <LabelInputContainer className="mb-8">
          <Label htmlFor="role">Role</Label>
          <select id="role" value={role} onChange={(e) => setRole(e.target.value as  'tm' | 'tl' )} className="block w-full rounded-md border-0 pl-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
            <option value="">Select Role</option>
            {/* <option value="user">User</option> */}
            <option value="tm">TM</option>
            <option value="tl">TL</option>
            {/* <option value="lcvp">LCVP</option>
            <option value="mcvp">MCVP</option>
            <option value="admin">Admin</option> */}
          </select>
        </LabelInputContainer>

        <button className="bg-gradient-to-br relative group/btn from-black dark:from-zinc-900 dark:to-zinc-900 to-neutral-600 block dark:bg-zinc-800 w-full text-white rounded-md h-10 font-medium shadow-[0px_1px_0px_0px_#ffffff40_inset,0px_-1px_0px_0px_#ffffff40_inset] dark:shadow-[0px_1px_0px_0px_var(--zinc-800)_inset,0px_-1px_0px_0px_var(--zinc-800)_inset]" type="submit">
          Sign up &rarr;
          <BottomGradient />
        </button>

        <div className="bg-gradient-to-r from-transparent via-neutral-300 dark:via-neutral-700 to-transparent my-8 h-[1px] w-full" />

        <div className="flex flex-col space-y-4">
          {/* <button onClick={signUpWithGoogle} className="relative group/btn flex space-x-2 items-center justify-start px-4 w-full text-black rounded-md h-10 font-medium shadow-input bg-gray-50 dark:bg-zinc-900 dark:shadow-[0px_0px_1px_1px_var(--neutral-800)]" type="button">
            <IconBrandGoogle className="h-4 w-4 text-neutral-800 dark:text-neutral-300" />
            <span className="text-neutral-700 dark:text-neutral-300 text-sm">Google</span>
            <BottomGradient />
          </button> */}
          <button onClick={() => { navigate('/signin') }} className="relative group/btn flex space-x-2 items-center justify-start px-4 w-full text-black rounded-md h-10 font-medium shadow-input bg-gray-50 dark:bg-zinc-900 dark:shadow-[0px_0px_1px_1px_var(--neutral-800)]" type="button">
            <span className="text-neutral-700 dark:text-neutral-300 text-sm">Sign In</span>
            <BottomGradient />
          </button>
        </div>
      </form>

      {message && <p className="mt-5 font-semibold text-center text-red-500">{message}</p>}
    </div>
  );
};

const BottomGradient = () => {
  return (
    <>
      <span className="group-hover/btn:opacity-100 block transition duration-500 opacity-0 absolute h-px w-full -bottom-px inset-x-0 bg-gradient-to-r from-transparent via-cyan-500 to-transparent" />
      <span className="group-hover/btn:opacity-100 blur-sm block transition duration-500 opacity-0 absolute h-px w-1/2 mx-auto -bottom-px inset-x-10 bg-gradient-to-r from-transparent via-indigo-500 to-transparent" />
    </>
  );
};

const LabelInputContainer = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => {
  return (
    <div className={cn("flex flex-col space-y-2 w-full", className)}>
      {children}
    </div>
  );
};

export default Signup;
