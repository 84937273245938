import React, { createContext, useContext, useEffect, useState, ReactNode } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import { auth, db } from '../firebase/firebase';

type Role = 'user' | 'tm' | 'tl' | 'lcvp' | 'mcvp' | 'admin';

interface UserContextType {
  user: any;
  userId: string | null;
  role: Role | null;
  loading: boolean;
  setRole: (role: Role | null) => void;
}

interface UserProviderProps {
  children: ReactNode;
}

export const UserContext = createContext<UserContextType | undefined>(undefined);

export const UserProvider: React.FC<UserProviderProps> = ({ children }) => {
  const [user, setUser] = useState<any>(null);
  const [userId, setUserId] = useState<string | null>(null);
  const [role, setRole] = useState<Role | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
  
      if (currentUser) {
        try {
          const userDocRef = doc(db, 'users', currentUser.uid);
          const userDoc = await getDoc(userDocRef);
  
          if (userDoc.exists()) {
            const userData = userDoc.data();
  
            if (!currentUser.emailVerified) {
              console.log('Email not verified');
              setUser(null);
              setUserId(null);
              setRole(null);
              setLoading(false);
              return;
            }
  
            setUser(currentUser);
            setUserId(currentUser.uid); 
            setRole(userData.role as Role);
          } else {
            setUser(null);
            setUserId(null);
            setRole(null);
          }
        } catch (error) {
          setUser(null);
          setUserId(null);
          setRole(null);
        }
      } else {
        setUser(null);
        setUserId(null);
        setRole(null);
      }
  
      setLoading(false);
    });
  
    return () => unsubscribe();
  }, []);
  

  return (
    <UserContext.Provider value={{ user, userId, role, loading, setRole }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
};
