import React from "react";

const Community = ({
  formValues,
  handleChange,
  nextStep,
  prevStep,
}: {
  formValues: any;
  handleChange: any;
  nextStep: () => void;
  prevStep: () => void;
}) => {
  return (
    <div className="p-6 rounded-lg shadow-md bg-white dark:bg-gray-800 space-y-4">
      <h2 className="text-3xl font-semibold mb-6 text-center">Community</h2>
      <div className="space-y-4">
        <div className="flex flex-col md:flex-row items-center justify-between">
          <label htmlFor="internalComms" className="block text-sm font-medium">
            Are you a part of your LC's Internal Communication Channels?
            (WhatsApp, Instagram, Facebook)
          </label>
          <input
            id="internalComms"
            type="checkbox"
            checked={formValues.internalComms}
            onChange={handleChange("community", "internalComms")}
            className="ml-2"
          />
        </div>

        <div className="flex flex-col md:flex-row items-center justify-between">
          <label htmlFor="portfolioComms" className="block text-sm font-medium">
            Are you a part of your Portfolio's Internal Communication Channel?
          </label>
          <input
            id="portfolioComms"
            type="checkbox"
            checked={formValues.portfolioComms}
            onChange={handleChange("community", "portfolioComms")}
            className="ml-2"
          />
        </div>

        <div className="flex flex-col md:flex-row items-center justify-between">
          <label
            htmlFor="nationalUpdates"
            className="block text-sm font-medium"
          >
            Do you keep track of National Updates? (National Initiatives,
            Projects, Roles and Seminars)
          </label>
          <input
            id="nationalUpdates"
            type="checkbox"
            checked={formValues.nationalUpdates}
            onChange={handleChange("community", "nationalUpdates")}
            className="ml-2"
          />
        </div>

        <div className="flex flex-col md:flex-row items-center justify-between">
          <label htmlFor="facebookGroup" className="block text-sm font-medium">
            Are you added to AIESEC Pakistan's Facebook Group?
          </label>
          <input
            id="facebookGroup"
            type="checkbox"
            checked={formValues.facebookGroup}
            onChange={handleChange("community", "facebookGroup")}
            className="ml-2"
          />
        </div>

        <div className="flex flex-col md:flex-row items-center justify-between">
          <label htmlFor="hrPolicy" className="block text-sm font-medium">
            Have you been downscaled the HR Policy?
          </label>
          <input
            id="hrPolicy"
            type="checkbox"
            checked={formValues.hrPolicy}
            onChange={handleChange("community", "hrPolicy")}
            className="ml-2"
          />
        </div>

        <div className="flex flex-col md:flex-row items-center justify-between">
          <label htmlFor="codeOfEthics" className="block text-sm font-medium">
            Have you been downscaled the Code of Ethics?
          </label>
          <input
            id="codeOfEthics"
            type="checkbox"
            checked={formValues.codeOfEthics}
            onChange={handleChange("community", "codeOfEthics")}
            className="ml-2"
          />
        </div>

        <div className="flex flex-col md:flex-row items-center justify-between">
          <label htmlFor="hrEthicsProof" className="block text-sm font-medium">
            Submit a proof that HR Policy & Code of Ethics downscaling took
            place in your LC.
          </label>
          <input
            id="hrEthicsProof"
            type="text"
            value={formValues.hrEthicsProof}
            onChange={handleChange("community", "hrEthicsProof")}
            placeholder="Enter HR Ethics Proof link"
            className="ml-2 border border-gray-300 rounded px-2 py-1"
          />
        </div>
      </div>
      <div className="flex justify-between space-x-4 mt-6">
        <button
          onClick={prevStep}
          className="px-6 py-2 bg-gray-500 text-white rounded hover:bg-gray-600"
        >
          Back
        </button>
        <button
          onClick={nextStep}
          className="px-6 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default Community;
