import React from "react";

const GeneralDetails = ({
  formValues,
  handleChange,
  nextStep,
}: {
  formValues: any;
  handleChange: any;
  nextStep: () => void;
}) => {
  return (
    <div className="p-6 rounded-lg shadow-md bg-white dark:bg-gray-800 space-y-4 sm:w-full">
      <h2 className="text-3xl font-semibold mb-6 text-center">General Details</h2>
      <div className="space-y-4">
        <div className="flex flex-col md:flex-row items-center justify-between">
          <label htmlFor="firstName" className="block text-sm font-medium">
            First Name
          </label>
          <input
            id="firstName"
            type="text"
            value={formValues.firstName}
            onChange={(e) => handleChange("generalDetail", "firstName")(e)} // Corrected here
            className="block w-full md:w-[60%] rounded-md border-0 py-1.5 text-gray-900 shadow-sm"
          />
        </div>

        <div className="flex flex-col md:flex-row items-center justify-between">
          <label htmlFor="lastName" className="block text-sm font-medium">
            Last Name
          </label>
          <input
            id="lastName"
            type="text"
            value={formValues.lastName}
            onChange={(e) => handleChange("generalDetail", "lastName")(e)} // Corrected here
            className="block w-full md:w-[60%] rounded-md border-0 py-1.5 text-gray-900 shadow-sm"
          />
        </div>

        <div className="flex flex-col md:flex-row items-center justify-between">
          <label htmlFor="portfolio" className="block text-sm font-medium">
            Portfolio
          </label>
          <select
            id="portfolio"
            value={formValues.portfolio}
            onChange={(e) => handleChange("generalDetail", "portfolio")(e)} // Corrected here
            className="block w-full md:w-[60%] rounded-md border-0 py-1.5 text-gray-900 shadow-sm"
          >
            <option value="" disabled>
              Select Portfolio
            </option>
            <option value="MKT & PR">MKT & PR</option>
            <option value="iCX">iCX</option>
            <option value="oGV">oGV</option>
            <option value="oGT">oGT</option>
            <option value="EwA">EwA</option>
            <option value="BD">BD</option>
            <option value="MXP">MXP</option>
            <option value="FnL">FnL</option>
          </select>
        </div>

        <div className="flex flex-col md:flex-row items-center justify-between">
          <label htmlFor="lcName" className="block text-sm font-medium">
            LC Name
          </label>
          <select
            id="lcName"
            value={formValues.lcName}
            onChange={(e) => handleChange("generalDetail", "lcName")(e)} // Corrected here
            className="block w-full md:w-[60%] rounded-md border-0 py-1.5 text-gray-900 shadow-sm"
          >
            <option value="" disabled>
              Select LC Name
            </option>
            <option value="LUMS">LUMS</option>
            <option value="Multan">Multan</option>
            <option value="IBA">IBA</option>
            <option value="Islamabad">Islamabad</option>
            <option value="NUST">NUST</option>
            <option value="South Karachi">South Karachi</option>
            <option value="Karachi">Karachi</option>
            <option value="Hyderabad">Hyderabad</option>
            <option value="Lahore">Lahore</option>
            <option value="Faisalabad">Faisalabad</option>
            <option value="Giki">Giki</option>
          </select>
        </div>
      </div>
      <div className="flex justify-end space-x-4 mt-6">
        <button
          onClick={nextStep}
          className="px-6 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default GeneralDetails;
