import React from "react";

const Environment = ({
  formValues,
  handleChange,
  nextStep,
  prevStep,
}: {
  formValues: any;
  handleChange: any;
  nextStep: () => void;
  prevStep: () => void;
}) => {
  return (
    <div className="p-6 rounded-lg shadow-md bg-white dark:bg-gray-800 space-y-4">
      <h2 className="text-3xl font-semibold mb-6 text-center">
        Environment & Wellbeing
      </h2>
      <div className="space-y-4">
        <div className="flex flex-col md:flex-row items-center justify-between">
          <label
            htmlFor="physicalWorkspace"
            className="block text-sm font-medium"
          >
            Has your Physical Workspace been decided with your Team?
          </label>
          <input
            id="physicalWorkspace"
            type="checkbox"
            checked={formValues.physicalWorkspace}
            onChange={handleChange("environmentWellbeing", "physicalWorkspace")}
            className="ml-2"
          />
        </div>

        <div className="flex flex-col md:flex-row items-center justify-between">
          <label
            htmlFor="virtualWorkspace"
            className="block text-sm font-medium"
          >
            Has your Virtual Workspace been decided with your Team?
          </label>
          <input
            id="virtualWorkspace"
            type="checkbox"
            checked={formValues.virtualWorkspace}
            onChange={handleChange("environmentWellbeing", "virtualWorkspace")}
            className="ml-2"
          />
        </div>

        <div className="flex flex-col md:flex-row items-center justify-between">
          <label htmlFor="workspacePlans" className="block text-sm font-medium">
            Document the Workspace Plans
          </label>
          <input
            id="workspacePlans"
            type="text"
            value={formValues.workspacePlans}
            onChange={handleChange("environmentWellbeing", "workspacePlans")}
            placeholder="Enter Workspace Plans link"
            className="ml-2 border border-gray-300 rounded px-2 py-1"
          />
        </div>

        <div className="flex flex-col md:flex-row items-center justify-between">
          <label htmlFor="o2oRoutine" className="block text-sm font-medium">
            Do you have an O2O Routine with your LCVP?
          </label>
          <input
            id="o2oRoutine"
            type="checkbox"
            checked={formValues.o2oRoutine}
            onChange={handleChange("environmentWellbeing", "o2oRoutine")}
            className="ml-2"
          />
        </div>

        <div className="flex flex-col md:flex-row items-center justify-between">
          <label htmlFor="o2oMembers" className="block text-sm font-medium">
            Do you have an O2O Routine with your Members, if any?
          </label>
          <input
            id="o2oMembers"
            type="checkbox"
            checked={formValues.o2oMembers}
            onChange={handleChange("environmentWellbeing", "o2oMembers")}
            className="ml-2"
          />
        </div>

        <div className="flex flex-col md:flex-row items-center justify-between">
          <label htmlFor="tlCoaching" className="block text-sm font-medium">
            Does your LC/LCVP provide a TL Coaching Routine? (Eg: Portfolio
            Meet)
          </label>
          <input
            id="tlCoaching"
            type="checkbox"
            checked={formValues.tlCoaching}
            onChange={handleChange("environmentWellbeing", "tlCoaching")}
            className="ml-2"
          />
        </div>

        <div className="flex flex-col md:flex-row items-center justify-between">
          <label
            htmlFor="mentalWellbeing"
            className="block text-sm font-medium"
          >
            Is your Mental Wellbeing catered to?
          </label>
          <input
            id="mentalWellbeing"
            type="checkbox"
            checked={formValues.mentalWellbeing}
            onChange={handleChange("environmentWellbeing", "mentalWellbeing")}
            className="ml-2"
          />
        </div>
      </div>
      <div className="flex justify-between space-x-4 mt-6">
        <button
          onClick={prevStep}
          className="px-6 py-2 bg-gray-500 text-white rounded hover:bg-gray-600"
        >
          Back
        </button>
        <button
          onClick={nextStep}
          className="px-6 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default Environment;
