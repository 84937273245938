import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useUser } from '../contexts/UserContext';
import { getDocs, collection, updateDoc, doc } from 'firebase/firestore';
import { db } from '../firebase/firebase';

interface User {
  id: string;
  email: string;
  role: string;
}

interface RoutePermission {
  _id: string;
  route: string;
  accessRoles: string[];
}

const AdminPanel: React.FC = () => {
  const [users, setUsers] = useState<User[]>([]);
  const [routePermissions, setRoutePermissions] = useState<RoutePermission[]>([]);
  const [filteredUsers, setFilteredUsers] = useState<User[]>([]);
  const [filteredRoutePermissions, setFilteredRoutePermissions] = useState<RoutePermission[]>([]);
  const [selectedRoles, setSelectedRoles] = useState<{ [key: string]: string[] }>({});
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const { role } = useUser();

  const [currentPage, setCurrentPage] = useState<number>(1);
  const usersPerPage = 10;

  useEffect(() => {
    const fetchUsersAndPermissions = async () => {
      try {
        const usersCollection = collection(db, 'users');
        const userDocs = await getDocs(usersCollection);
        const usersList = userDocs.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        })) as User[];

        const routesResponse = await axios.get('https://api.nexusmxp.com/api/route-permissions');
        setUsers(usersList);
        setFilteredUsers(usersList);
        setRoutePermissions(routesResponse.data);
        setFilteredRoutePermissions(routesResponse.data);

        // Initialize selectedRoles with current accessRoles
        const roles = routesResponse.data.reduce((acc: { [key: string]: string[] }, route: RoutePermission) => {
          acc[route._id] = route.accessRoles;
          return acc;
        }, {});
        setSelectedRoles(roles);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    if (role === 'admin') {
      fetchUsersAndPermissions();
    }
  }, [role]);

  useEffect(() => {
    const filteredUsers = users.filter((user) =>
      (user.email?.toLowerCase() || "").includes(searchTerm.toLowerCase())
    );
    setFilteredUsers(filteredUsers);
  
    const filteredRoutes = routePermissions.filter((route) =>
      (route.route?.toLowerCase() || "").includes(searchTerm.toLowerCase())
    );
    setFilteredRoutePermissions(filteredRoutes);
  }, [searchTerm, users, routePermissions]);
  

  const handleChangeUserRole = async (userId: string, newRole: string) => {
    try {
      const userDoc = doc(db, 'users', userId);
      await updateDoc(userDoc, { role: newRole });
      setUsers(users.map((user) => (user.id === userId ? { ...user, role: newRole } : user)));
    } catch (error) {
      console.error('Error updating user role:', error);
    }
  };

  const handleRoleChange = (id: string, roles: string[]) => {
    setSelectedRoles((prevSelectedRoles) => ({
      ...prevSelectedRoles,
      [id]: roles,
    }));
  };

  const handleSubmit = async (id: string) => {
    try {
      const newRoles = selectedRoles[id];
      const response = await axios.put(`https://api.nexusmxp.com/api/route-permissions/${id}`, { accessRoles: newRoles });
      setRoutePermissions(routePermissions.map((route) => (route._id === id ? response.data : route)));
      setSuccessMessage('Route permissions updated successfully!');
      setTimeout(() => setSuccessMessage(null), 3000); // Hide message after 3 seconds
    } catch (error) {
      console.error('Error updating route permission:', error);
    }
  };

  // Pagination
  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const currentUsers = filteredUsers.slice(indexOfFirstUser, indexOfLastUser);

  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col items-center p-4">
      <h1 className="text-3xl font-bold mb-4">Admin Panel</h1>
      <div className="w-full max-w-4xl mb-4">
        <input
          type="text"
          placeholder="Search..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="w-full p-2 border rounded-md mb-4"
        />
      </div>
      <h2 className="text-2xl font-semibold mb-4">Manage Users</h2>
      <table className="min-w-full bg-white mb-8 rounded-md shadow-md">
        <thead>
          <tr className="bg-gray-200">
            <th className="px-4 py-2">Email</th>
            <th className="px-4 py-2">Role</th>
          </tr>
        </thead>
        <tbody>
          {currentUsers.map((user) => (
            <tr key={user.id} className="border-t">
              <td className="border px-4 py-2">{user.email}</td>
              <td className="border px-4 py-2">
                <select value={user.role} onChange={(e) => handleChangeUserRole(user.id, e.target.value)} className="p-2 border rounded-md">
                  <option value="user">User</option>
                  <option value="tm">TM</option>
                  <option value="tl">TL</option>
                  <option value="lcvp">LCVP</option>
                  <option value="mcvp">MCVP</option>
                  <option value="admin">Admin</option>
                </select>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="flex justify-center mb-8">
        {Array.from({ length: Math.ceil(filteredUsers.length / usersPerPage) }, (_, i) => (
          <button
            key={i + 1}
            onClick={() => paginate(i + 1)}
            className={`px-3 py-1 border rounded-md mx-1 ${
              currentPage === i + 1 ? 'bg-blue-500 text-white' : 'bg-white'
            }`}
          >
            {i + 1}
          </button>
        ))}
      </div>
      <h2 className="text-2xl font-semibold mb-4">Manage Route Permissions</h2>
      {successMessage && <div className="mb-4 text-green-500">{successMessage}</div>}
      <table className="min-w-full bg-white rounded-md shadow-md">
        <thead>
          <tr className="bg-gray-200">
            <th className="px-4 py-2">Route</th>
            <th className="px-4 py-2">Access Roles</th>
            <th className="px-4 py-2">Actions</th>
          </tr>
        </thead>
        <tbody>
          {filteredRoutePermissions.map((route) => (
            <tr key={route._id} className="border-t">
              <td className="border px-4 py-2">{route.route}</td>
              <td className="border px-4 py-2">
                <select
                  multiple
                  value={selectedRoles[route._id] || []}
                  onChange={(e) =>
                    handleRoleChange(
                      route._id,
                      Array.from(e.target.selectedOptions, (option) => option.value)
                    )
                  }
                  className="w-full p-2 border rounded-md"
                >
                  <option value="user">User</option>
                  <option value="tm">TM</option>
                  <option value="tl">TL</option>
                  <option value="lcvp">LCVP</option>
                  <option value="mcvp">MCVP</option>
                  <option value="admin">Admin</option>
                </select>
              </td>
              <td className="border px-4 py-2">
                <button
                  onClick={() => handleSubmit(route._id)}
                  className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition"
                >
                  Submit
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default AdminPanel;
